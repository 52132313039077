import React, { Component, Fragment } from 'react';

import googleBadge from "../assets/logo/google-play-badge.png";

export default class AndroidLink extends Component {
  render() {
    return (
      <Fragment>
        <a 
          href="https://play.google.com/store/apps/details?id=com.app.brainblurb" 
          target="_blank" 
          rel="noreferrer"
          className="text-decoration-none d-flex"
        >
          <div className="col-6 col-md-8">
              <p>
                Download BrainblurbApp from Play Store!
              </p>
          </div>
          <div className="col-6 col-md-4">
            <img src={googleBadge} alt="Welcome to BrainBlurb sharepoint!" className="img-fluid" />
          </div>
        </a>
      </Fragment>
    )
  }
}
