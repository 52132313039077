import React, { Component } from 'react';

import brainblurbLogo from "../assets/logo/brainblurb-logo.png";
import introImg from "../assets/images/into-img.png";

import googleBadge from "../assets/logo/google-play-badge.png";
import appleBadge from "../assets/logo/apple-badge.png";
import CardContent from '../components/CardContent';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const initialState = {
  ventureFetched: false,
  venture: null, 
  // err: {}
};

class Venture extends Component {
  state = initialState;

  componentDidMount = () => {
    this.fetchVenture();
  }

  fetchVenture = () => {
    const { id } = this.props.params;
    axios
      .get(`https://api.brainblurb.com/items/venture/${id}`)
      .then((venture) => {
        this.setState({
          ventureFetched: true,
          venture: venture?.data?.data
        })
      })
      .catch((err) => {
        // this.setState({err: err})
        this.props.navigate("/");
      })
  }


  render() {
    return (
      <div className="container-fluid min-vh-100 d-flex flex-column">
        <div className="row flex-grow-1">
          <div className="col-12 col-md-6 order-2 order-md-1"
            style={{
              backgroundColor: "#F3F8FD",
            }}
          >
            <div className="row min-vh-100">
              <div className="col align-self-center">
                <div className="row justify-content-center">
                  <img src={introImg} alt="Welcome to BrainBlurb sharepoint!" className="img-fluid w-50" />
                </div>
                <div className="row justify-content-center mt-3 mt-md-5">
                  <h1 className="mt-5 text-center w-100 w-md-75"
                    style={{
                      color: "#081F32",
                      fontStyle: "normal",
                      fontWeight: 700,
                    }}
                  >
                    Welcome to BrainBlurb sharepoint
                  </h1>
                </div>
                <div className="row justify-content-center">
                  <p className="text-center mt-3 mt-md-5 w-75 w-md-50"
                    style={{
                      color: "#6E798C",
                      fontStyle: "normal",
                      fontWeight: 400,
                    }}
                  >
                    Join our online community to connect with other co-founders and start building new ventures!
                  </p>
                </div>
              </div>
              <div className="row align-self-end pb-5 align-items-arround">
                <div className="col-12 col-md-6 text-center">
                  <a 
                    href="https://play.google.com/store/apps/details?id=com.app.brainblurb" 
                    target="_blank" 
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="text-center px-5">
                      Download BrainblurbApp from Play Store!
                    </p>
                    <img src={googleBadge} alt="Welcome to BrainBlurb sharepoint!" className="img-fluid w-50" />
                  </a>
                </div>

                <div className="col-12 col-md-6 text-center mt-5 mt-md-0">
                  <a 
                    href="https://apps.apple.com/us/app/brainblurb/id1498166644" 
                    target="_blank" 
                    rel="noreferrer"
                    className="text-decoration-none"
                  >
                    <p className="text-center px-5">
                      Download BrainblurbApp from App Store!
                    </p>
                    <img src={appleBadge} alt="Download Brainblurb App from " className="img-fluid w-50" />
                  </a>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="row align-self-start my-5 justify-content-center">
              <div className="col-6 col-md-3">
                <img src={brainblurbLogo} alt="Welcome to BrainBlurb sharepoint!" className="img-fluid" />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h2 className="text-center">
                  <b>
                    Someone have shared <br />
                    this Venture with you!
                  </b>
                </h2>
              </div>
            </div>

            <div className="row justify-content-center my-3 my-md-5"
              style={{
                minHeight: '50%',
              }}
            >
              <div className="card w-75"
                style={{
                  boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.14)",
                  borderRadius: 12
                }}
              >
                {
                  (this.state.ventureFetched) ? (
                    <CardContent venture={this.state.venture} />
                  ) : (
                    <div className="d-flex justify-content-center pt-5">
                      <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default function RootFunction (props){
  const navigate = useNavigate();
  const params = useParams();

  return <Venture 
    props={props} 
    navigate={navigate} 
    params={params}
  />
}