import React, { Component } from 'react';
import { getMobileOperatingSystem } from '../functions/helpers';
import AndroidLink from './AndroidLink';
import IosLink from './IosLink';

export default class CardContent extends Component {
  render() {
    const operatingSystem = getMobileOperatingSystem();
    return (
      <div className="row h-100 m-3 m-md-4">
        <div className="col-12">
          <div className="row">
            <h5>
              <b>
                {this.props.venture?.name}
              </b>
            </h5>
          </div>
          <div className="row">
            <p
              className="mt-2 mt-md-4"
              style={{
                color: "#80838B"
              }}
            >
              {this.props.venture?.description}
            </p>
          </div>
        </div>

        <div className="mt-auto col-12 pb-md-5">
          <div className="row">
            <div className="col-6">
              <h5>Members</h5>
            </div>
            <div className="col-6 text-end">
              
            </div>
          </div>
          <div className="row mt-3">
            {
              (operatingSystem) ? (
                (operatingSystem === "Android") ? (
                  <AndroidLink />
                 ) : (
                  (operatingSystem === "iOS") ? (
                    <IosLink />
                  ) : (
                    <></>
                  )
                )
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
