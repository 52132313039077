import React, { Component, Fragment } from 'react';

import appleBadge from "../assets/logo/apple-badge.png";

export default class IosLink extends Component {
  render() {
    return (
      <Fragment>
        <a 
          href="https://apps.apple.com/us/app/brainblurb/id1498166644" 
          target="_blank" 
          rel="noreferrer"
          className="text-decoration-none d-flex"
        >
          <div className="col-6 col-md-8">
              <p>
                Download BrainblurbApp from Play Store!
              </p>
          </div>
          <div className="col-6 col-md-4">
            <img src={appleBadge} alt="Download Brainblurb App from " className="img-fluid w-50" />
          </div>
        </a>
      </Fragment>
    )
  }
}
