import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Index from "./routes/Index";
import Venture from "./routes/Venture";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/venture/:id" element={<Venture />} />
        <Route 
          path="*"
          element={<Navigate to="/" replace />}
        />

      </Routes>    
    </BrowserRouter>
  );
}

export default App;
